@import url('https://fonts.googleapis.com/css2?family=Homemade+Apple&family=Inconsolata:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');


  

/* Main Elements */

.main-wrapper {
  display: flex;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  background-color: rgb(19, 19, 19);
  font-size: 1rem;
  font-family: Inconsolata, monospace, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: white;
  text-shadow: 0 0 5px #c8c8c8;
  z-index: 0;
}

.monitor {
  display: flex;
  flex-direction: column;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  padding: 20px;
  background-color: rgb(0, 15, 0);
  z-index: 4;

}

.monitor::after {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(18, 16, 16, 0.1);
  opacity: 0;
  z-index: 2;
  pointer-events: none;
  z-index: 4;
}

.monitor::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%), linear-gradient(90deg, rgba(255, 0, 0, 0.06), rgba(0, 255, 0, 0.02), rgba(0, 0, 255, 0.06));
  background-size: 100% 2px, 3px 100%;
  pointer-events: none;
  z-index: 4;
}

svg {
  width: 40px;
  margin: 10px;
  height: auto;
  stroke: white;
  fill: white;
  filter: drop-shadow(0 0 5px #c8c8c8);
  cursor: pointer;
}

a:visited {
  color: white;
}

.footer {
  position: fixed;
  bottom: 2%;
  font-size: .7rem;
  text-align: center;
  line-height: 1.5rem;
}

.footer a {
color: inherit; 
}

.preload {
  position: absolute;
  overflow: hidden;
  left: -9999px; 
  top: -9999px;
  height: 1px;
  width: 1px;
}


/* Orientation Lock*/

.rotate {
  position: fixed;
  display: flex;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: rgb(0, 15, 0);
  visibility: hidden;
  z-index: 3;
}

.rotate svg {
  width: 75px;
}

@media screen and (max-device-width: 1000px) and (orientation:landscape) {
  .rotate {
    visibility: visible;
    opacity: .95;
  }

  .desktop-typist {
    visibility: hidden;
  }
}


/* Power */

.boottext-wrapper {
  width: 100%;
  height: 100%;
  vertical-align: top;
  justify-content: left;
  align-items: left;
}

.power-button {
  display: flex;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background-color: black;
  border: 2px solid black;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.55);
}

.button {
  position: relative;
  color: black;
  text-shadow: none;
  display: flex;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  height: 75px;
  width: 75px;
  background-image: linear-gradient(#333, #222);
  text-align: center;
  vertical-align: middle;
  border-radius: 50px;
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  border-left: 1px solid rgba(255, 255, 255, 0.05);
  border-right: 1px solid rgba(255, 255, 255, 0.05);
  box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.175s ease;
}

.selected {
  z-index: 3;
  cursor: default;
  background-image: linear-gradient(#202020, #151515);
  border-top-color: rgba(0, 0, 0, 0);
  border-left-color: rgba(0, 0, 0, 0.55);
  border-right-color: rgba(0, 0, 0, 0.55);
  box-shadow: inset 0 1px 6px rgba(0, 0, 0, 0.5), 0 0 20px rgba(0, 255, 34, 0.06);
  color: rgba(0, 255, 34, 1);
  text-shadow: 0 0 10px rgba(0, 255, 34, 0.75);
}


/* Welcome */

.welcome-typist {
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
}

input[type=text] {
  background-color: transparent;
  border: 0px;
  font-size: 1.2rem;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  color: white;
  text-shadow: 0 0 5px #c8c8c8;
  text-align: left;
}

label {
  display: block;
  float: left;
  font-size: 1.2rem;
}

form {
  padding: 10px;
}

.form-tip {
  font-size: .8rem;
  opacity: .6;
  text-align: center;
}

.cookie-policy {
  color:white;
}

/* Desktop */

.desktop-typist {
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  height: 90%;
  max-height: 600px;
}


.app-wrapper {
  position: fixed;
  display: grid;
  height:350px;
  width:305px;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 50px;
  grid-auto-rows: minmax(50px, auto);
}

.apps {
  display: flex;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: .8rem;
  text-transform: uppercase;
  cursor: pointer;
}

.close {
  position: absolute;
  width: 20px;
  top: -38px;
  right: -11px;
  z-index: 0;
}


.window {
  position: fixed;
  height: 85%;
  max-width: 900px;
  width: 90%;
  max-height: 675px;
  background-color: rgb(0, 14, 0);
  border: 1px solid white;
  border-radius: 0px 0px 5px 5px;
  box-shadow: 0 0 5px #c8c8c8;
  z-index: 2;
}

.window-bar {
  position: absolute;
  top: 0;
  height: 5px;
  width: 100%;
  background-color: white;
  box-shadow: 0 0 5px #c8c8c8;
  border-radius: 0;
  z-index: 3;
}

.popup {
  position: fixed;
  display: flex;
  flex-direction: row;
  text-align: center;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 325px;
  font-size: .9rem;
  line-height: 1.2rem;
  background-color: rgb(0, 14, 0);
  border: 1px solid white;
  border-radius: 0px 0px 5px 5px;
  box-shadow: 0 0 5px #c8c8c8;
  z-index: 1;
}

.popup a {
  color: inherit; 
  text-decoration: none;
  }
  

.popup-close {
  position: fixed;
  display: flex;
  text-align: center;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 4;
}

.popup-button {
  display: flex;
  width: 33%;
  height: 30px;
  border-radius: 3px;
  background-color: none;
  border: 1px solid white;
  box-shadow: 0 0 5px #c8c8c8;
  text-align: center;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.popup-button:hover {
  color: black;
  background-color: white;
}

.download-button {
  display: flex;
  width: 150px;
  height: 50px;
  border-radius: 3px;
  background-color: none;
  border: 1px solid white;
  box-shadow: 0 0 5px #c8c8c8;
  text-align: center;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.download-button:hover {
  color: black;
  background-color: white;
}


.shutdown {
  display: flex;
  flex-direction: row;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  width:100%;
  z-index: 1;
}


/* About */

.about-content {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow-y: scroll;
}

.about-content::-webkit-scrollbar {
  display: none;
}

.about-content {
  -ms-overflow-style: none;
}

.about-text {
  width: 82%;
  font-size: .9rem;
  padding:30px;
  text-align: justify;
  line-height: 1.05rem;
}

.freebirdFormviewerComponentsQuestionBaseRoot {
  color: red;
}

.about-photo {
  background-image: url(../assets/about/aboutBW.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 18%;
  opacity: .6;
  height: 100%;
  float: right;
  border-radius: 0px 5px 5px 0px;
}

.sign-off {
  font-family: 'Homemade Apple', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 2rem;
  line-height: 5rem;
}


/* Skills */

.skills-wrapper {
  width: 100%;
  height: 100%;
  padding: 20px;
  overflow-y: scroll;
  z-index: 1;
}

.skills-wrapper::-webkit-scrollbar {
  display: none;
}

.skills-wrapper {
  -ms-overflow-style: none;
}

.skills-header {
  width: 100%;
  border-collapse: collapse;
}

.skills-header td {
  padding: 5px;
}

.skills-table {
  text-align: left;
  width: 100%;
  border-collapse: collapse;
}

.skills-table td {
  padding: 5px;
}

.skills-table tr {
  transition: all .1s ease-out;
}

.skills-table tr:hover {
  background-color: #ffffff;
  color: rgb(0, 13, 0);
  cursor: pointer;
  box-shadow: 0 0 5px #c8c8c8;
}

.skills-expanded tr {
  background-color: #ffffff;
  color: rgb(0, 13, 0);
  cursor: pointer;
  box-shadow: 0 0 5px #c8c8c8;
}

.skills-content {
  text-align: justify;
  font-size: .9rem;
  border: 1px solid white;
  box-shadow: 0 0 5px #c8c8c8;
  padding: 10px;
  margin-bottom: 5px;
}

@media screen and (max-width: 640px) {
  .hide-column {
    display: none;
  }

  .grow-column {
    width: 100%;
  }

}


/* Software */

.software-content {
  width: 100%;
  height: 100%;
  z-index: 1;
}

.arrow{
  position: absolute;
  top: 71%;
  right: 10px;
  transform: translate(-50%,-50%);
  filter: drop-shadow(0 0 5px #c8c8c8);
}
.arrow span{
  display: block;
  opacity: 0;  
  width: 15px;
  height: 15px;
  border-bottom: 4px solid #ffffff;
  border-right: 4px solid #ffffff;
  transform: rotate(45deg);
  margin: -4px;
  animation: down-arrow 2s;
  animation-delay: 1s;
}
.arrow span:nth-child(2){
  animation-delay: .8s;
}
.arrow span:nth-child(3){
  animation-delay: .6s;
}

.software-icon-wrapper {
  height: 80%;
  width: 100%;
  display: grid;
  padding-top:20px;
  border-bottom: 1px solid white;
  border-radius: 0;
  box-shadow: 0 0 5px #c8c8c8;
  grid-auto-rows: minmax(150px, 1);
  grid-template-columns: repeat(5, 1fr);
  overflow-x: scroll;
}

@media screen and (max-width: 640px) {
  .software-icon-wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}

.software-icon-wrapper::-webkit-scrollbar {
  display: none;
}

.software-icon-wrapper {
  -ms-overflow-style: none;
}

.software-icon {
  display: flex;
  flex-direction: column;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: .9rem;
  height: 130px;
}

.software-icon svg {
  width: 50px;
}

.software-icon-selection {
  display: flex;
  flex-direction: column;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  transition: all .2s ease-in-out;
}

.software-icon-selection:hover {
  transform: scale(1.03);
}


.preview-bar {
  height: 20%;
  display: flex;
  text-align: center;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
}

.preview-tip {
  position: fixed;
  opacity: .3;
  z-index: 1;
}

.preview-tip svg{
  width:65px;
}

.preview {
  width: 90%;
  height:19%;
  position: absolute;
  display: flex;
  text-align: center;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-color: rgb(0, 14, 0);
}

.hidden {
  display: none;
}

.preview-icon {
  width: 20%;
  max-width: 100px;
  height: 100%;
  filter: grayscale(90%);
  display: flex;
  text-align: center;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
}

.preview-icon img {
  width: 100%;
}

.preview-spacer {
  width: 5%;
}

.preview-title {
 font-size: 1.3em;
 font-weight: bold;
}

.preview-text {
  width: 75%;
  height: 100px;
  max-width: 800px;
  font-size: .75rem;
  display: flex;
  text-align: justify;
  vertical-align: middle;
  align-items: center;
}


/* Resume */

.resume-content {
  display: flex;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: .9rem;
}

.resume {
  border: none;
  width: 100%;
  height: 100%;
  border-radius: 0px 0px 4px 4px;
  -webkit-filter: grayscale(90%);
  -moz-filter: grayscale(90%);
  -ms-filter: grayscale(90%);
  -o-filter: grayscale(90%);
  filter: grayscale(90%);
}


/* Find Josh */

.maps-content {
  display: flex;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: .9rem;
}

.map {
  border: none;
  width: 100%;
  height: 100%;
  border-radius: 0px 0px 4px 4px;
  -webkit-filter: grayscale(90%);
  -moz-filter: grayscale(90%);
  -ms-filter: grayscale(90%);
  -o-filter: grayscale(90%);
  filter: grayscale(90%);
}

 /* Happy Hour */

.happy-hour-window {
  position: fixed;
  height: 85%;
  max-width: 500px;
  width: 90%;
  max-height: 675px;
  background-color: rgb(0, 14, 0);
  border-radius: 0px 0px 5px 5px;
  box-shadow: 0 0 5px #c8c8c8;
  z-index: 1;
}

.happy-hour-content {
  position: absolute;
  width: 100%;
  height: 85%;
  z-index: 2;
  overflow-y: scroll;
}

.happy-hour-content::-webkit-scrollbar {
  display: none;
}

.happy-hour-content {
  -ms-overflow-style: none;
}

.happy-hour-header {
  text-align: center;
  width: 100%;
  font-family: 'Homemade Apple', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1.8rem;
  line-height: 3.5rem;
  padding: 30px 20px 0px 20px;

}

.break {
  padding: 20px;
  text-align: center;
}

.happy-hour-wrapper {
  display: flex;
  flex-wrap: wrap;
  font-size: 1rem;
}

.table-left {
  width: 30%;
  font-weight: bold;
  padding:20px 30px 20px 30px;
}

.table-right {
  width:70%;
  padding:20px 30px 20px 30px;
}

.mix {
  position: absolute;
  text-align:center;
  font-size: .75rem;
  padding:30px;
  bottom:0;
  z-index: 3;
}

.responsibly {
  position: absolute;
  text-align:center;
  font-size: .75rem;
  opacity: .6;
  padding:30px;
  bottom:0;
  right:0;
}


@media screen and (max-height: 850px) and (max-width: 375px) {
  .happy-hour-header {
    font-size: 1.3rem;
    }

    .break {
      padding: 0px;
    }

    .happy-hour-wrapper {
     font-size: .80rem;
    }

}



/* Animations */


.turn-on {
  animation: turn-on 4s linear;
  animation-fill-mode: forwards;
}

@keyframes turn-on {
  0% {
    transform: scale(1, 0.8) translate3d(0, 0, 0);
    -webkit-filter: brightness(30);
    filter: brightness(30);
    opacity: 1;
  }

  3.5% {
    transform: scale(1, 0.8) translate3d(0, 100%, 0);
  }

  3.6% {
    transform: scale(1, 0.8) translate3d(0, -100%, 0);
    opacity: 1;
  }

  9% {
    transform: scale(1.3, 0.6) translate3d(0, 100%, 0);
    -webkit-filter: brightness(30);
    filter: brightness(30);
    opacity: 0;
  }

  11% {
    transform: scale(1, 1) translate3d(0, 0, 0);
    -webkit-filter: contrast(0) brightness(0);
    filter: contrast(0) brightness(0);
    opacity: 0;
  }

  100% {
    transform: scale(1, 1) translate3d(0, 0, 0);
    -webkit-filter: contrast(1) brightness(1) saturate(1);
    filter: contrast(1) brightness(1) saturate(1);
    opacity: 1;
  }
}

.Cursor {
  -webkit-animation: 1s blink step-end infinite;
  -moz-animation: 1s blink step-end infinite;
  -ms-animation: 1s blink step-end infinite;
  -o-animation: 1s blink step-end infinite;
  animation: 1s blink step-end infinite;
}

@keyframes blink {

  from,
  to {
    visibility: hidden;
  }

  50% {
    visibility: visible;
  }
}

.turn-off {
  animation: turn-off 0.55s cubic-bezier(0.23, 1, 0.32, 1);
  animation-fill-mode: forwards;
}

@keyframes turn-off {
  0% {
    transform: scale(1, 1.3) translate3d(0, 0, 0);
    -webkit-filter: brightness(1);
    filter: brightness(1);
    opacity: 1;
  }

  60% {
    transform: scale(1.3, 0.001) translate3d(0, 0, 0);
    -webkit-filter: brightness(10);
    filter: brightness(10);
  }

  100% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: scale(0, 0.0001) translate3d(0, 0, 0);
    -webkit-filter: brightness(50);
    filter: brightness(50);
  }
}

.fade-out {
  -webkit-animation: fade-out 500ms ease-out both;
  animation: fade-out 500ms ease-out both;
}

.quick-fade-out {
  -webkit-animation: fade-out 200ms ease-out both;
  animation: fade-out 200ms ease-out both;
}

@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.fade-in {
  -webkit-animation: fade-in .8s ease-out both;
  animation: fade-in .8s ease-out both;
}

.quick-fade-in {
  -webkit-animation: fade-in 200ms ease-out both;
  animation: fade-in 200ms ease-out both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.scale-in-hor-center {
  -webkit-animation: scale-in-hor-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: scale-in-hor-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes scale-in-hor-center {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    opacity: 1;
  }
}

@keyframes scale-in-hor-center {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    opacity: 1;
  }
}

.scale-out-horizontal {
  -webkit-animation: scale-out-horizontal 0.3s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  animation: scale-out-horizontal 0.3s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

@-webkit-keyframes scale-out-horizontal {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    opacity: 1;
  }
}

@keyframes scale-out-horizontal {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    opacity: 1;
  }
}


.fade-in-tl {
  -webkit-animation: fade-in-tl 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in-tl 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes fade-in-tl {
  0% {
    -webkit-transform: translateX(-50px) translateY(-50px);
    transform: translateX(-50px) translateY(-50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in-tl {
  0% {
    -webkit-transform: translateX(-50px) translateY(-50px);
    transform: translateX(-50px) translateY(-50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
    opacity: 1;
  }
}

.fade-in-top {
  -webkit-animation: fade-in-top 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in-top 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.fade-in-tr {
  -webkit-animation: fade-in-tr 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in-tr 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes fade-in-tr {
  0% {
    -webkit-transform: translateX(50px) translateY(-50px);
    transform: translateX(50px) translateY(-50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in-tr {
  0% {
    -webkit-transform: translateX(50px) translateY(-50px);
    transform: translateX(50px) translateY(-50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
    opacity: 1;
  }
}

.fade-in-left {
  -webkit-animation: fade-in-left 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in-left 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.fade-in-fwd {
  -webkit-animation: fade-in-fwd 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in-fwd 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes fade-in-fwd {
  0% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes fade-in-fwd {
  0% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

.fade-in-right {
  -webkit-animation: fade-in-right 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in-right 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.fade-in-bl {
  -webkit-animation: fade-in-bl 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in-bl 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes fade-in-bl {
  0% {
    -webkit-transform: translateX(-50px) translateY(50px);
    transform: translateX(-50px) translateY(50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in-bl {
  0% {
    -webkit-transform: translateX(-50px) translateY(50px);
    transform: translateX(-50px) translateY(50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
    opacity: 1;
  }
}

.fade-in-bottom {
  -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.fade-in-br {
  -webkit-animation: fade-in-br 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in-br 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes fade-in-br {
  0% {
    -webkit-transform: translateX(50px) translateY(50px);
    transform: translateX(50px) translateY(50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in-br {
  0% {
    -webkit-transform: translateX(50px) translateY(50px);
    transform: translateX(50px) translateY(50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
    opacity: 1;
  }
}

.scale-in-tl {
	-webkit-animation: scale-in-tl 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-tl 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

 @-webkit-keyframes scale-in-tl {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes scale-in-tl {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}


.shake-top {
	-webkit-animation: shake-top 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
	        animation: shake-top 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}

 @-webkit-keyframes shake-top {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: 50% 0;
            transform-origin: 50% 0;
  }
  10% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
}
@keyframes shake-top {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: 50% 0;
            transform-origin: 50% 0;
  }
  10% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
}

@keyframes down-arrow {
  0%{
      opacity: 0;
      transform: rotate(45deg) translate(-20px,-20px);
  }
  50%{
      opacity: 1;
  }
  100%{
      opacity: 0;
      transform: rotate(45deg) translate(20px,20px);
  }
}


.skills-wrapper div:nth-child(1) {
  animation-delay: .0s
}

.skills-wrapper div:nth-child(2) {
  animation-delay: .0s
}

.skills-wrapper div:nth-child(3) {
  animation-delay: .0s
}

.skills-wrapper div:nth-child(4) {
  animation-delay: .2s
}

.skills-wrapper div:nth-child(5) {
  animation-delay: .3s
}

.skills-wrapper div:nth-child(6) {
  animation-delay: .4s
}

.skills-wrapper div:nth-child(7) {
  animation-delay: .5s
}

.skills-wrapper div:nth-child(8) {
  animation-delay: .6s
}

.skills-wrapper div:nth-child(9) {
  animation-delay: .7s
}

.skills-wrapper div:nth-child(10) {
  animation-delay: .8s
}

.skills-wrapper div:nth-child(11) {
  animation-delay: .9s
}

.skills-wrapper div:nth-child(12) {
  animation-delay: 1s
}

.skills-wrapper div:nth-child(13) {
  animation-delay: 1.1s
}

.skills-wrapper div:nth-child(14) {
  animation-delay: 1.2s
}

.skills-wrapper div:nth-child(15) {
  animation-delay: 1.3s
}

.skills-wrapper div:nth-child(16) {
  animation-delay: 1.4s
}

.skills-wrapper div:nth-child(17) {
  animation-delay: 1.5s
}

.skills-wrapper div:nth-child(18) {
  animation-delay: 1.6s
}

.skills-wrapper div:nth-child(19) {
  animation-delay: 1.7s
}

.skills-wrapper div:nth-child(20) {
  animation-delay: 1.8s
}

.skills-wrapper div:nth-child(20) {
  animation-delay: 1.9s
}



.software-icon-wrapper div:nth-child(1) {
  animation-delay: .0s
}

.software-icon-wrapper div:nth-child(2) {
  animation-delay: .03s
}

.software-icon-wrapper div:nth-child(3) {
  animation-delay: .06s
}

.software-icon-wrapper div:nth-child(4) {
  animation-delay: .09s
}

.software-icon-wrapper div:nth-child(5) {
  animation-delay: .12s
}

.software-icon-wrapper div:nth-child(6) {
  animation-delay: .15s
}

.software-icon-wrapper div:nth-child(7) {
  animation-delay: .18s
}

.software-icon-wrapper div:nth-child(8) {
  animation-delay: .21s
}

.software-icon-wrapper div:nth-child(9) {
  animation-delay: .24s
}

.software-icon-wrapper div:nth-child(10) {
  animation-delay: .27s
}

.software-icon-wrapper div:nth-child(11) {
  animation-delay: .3s
}

.software-icon-wrapper div:nth-child(12) {
  animation-delay: .33s
}

.software-icon-wrapper div:nth-child(13) {
  animation-delay: .36s
}

.software-icon-wrapper div:nth-child(14) {
  animation-delay: .39s
}

.software-icon-wrapper div:nth-child(15) {
  animation-delay: .42s
}

.software-icon-wrapper div:nth-child(16) {
  animation-delay: .45s
}

.software-icon-wrapper div:nth-child(17) {
  animation-delay: .48s
}

.software-icon-wrapper div:nth-child(18) {
  animation-delay: .51s
}

.software-icon-wrapper div:nth-child(19) {
  animation-delay: .54s
}

.software-icon-wrapper div:nth-child(20) {
  animation-delay: .57s
}

.software-icon-wrapper div:nth-child(21) {
  animation-delay: .60s
}

.software-icon-wrapper div:nth-child(22) {
  animation-delay: .63s
}

.software-icon-wrapper div:nth-child(23) {
  animation-delay: .66s
}

.software-icon-wrapper div:nth-child(24) {
  animation-delay: .69s
}

.software-icon-wrapper div:nth-child(25) {
  animation-delay: .72s
}



.app-wrapper div:nth-child(1) {
  animation-delay: .0s
}

.app-wrapper div:nth-child(2) {
  animation-delay: .1s
}

.app-wrapper div:nth-child(3) {
  animation-delay: .2s
}

.app-wrapper div:nth-child(4) {
  animation-delay: .3s
}

.app-wrapper div:nth-child(5) {
  animation-delay: .4s
}

.app-wrapper div:nth-child(6) {
  animation-delay: .5s
}

.app-wrapper div:nth-child(7) {
  animation-delay: .6s
}

.app-wrapper div:nth-child(8) {
  animation-delay: .7s
}

.app-wrapper div:nth-child(9) {
  animation-delay: .8s
}

.app-wrapper div:nth-child(10) {
  animation-delay: .9s
}

